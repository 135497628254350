import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {IPropsInjectedByViewerScript, IWishlistStyleParams, IProduct} from '../types/app-types';
import {IControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {MultilingualService} from '@wix/wixstores-client-core/dist/es/src/multilingualService/multilingualService';
import {APP_DEFINITION_ID, PageMap, ActionStatus} from '@wix/wixstores-client-core/dist/es/src/constants';
import {WishlistService} from '../services/WishlistService';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {ProductActions} from '@wix/wixstores-client-core/dist/es/src/product-actions/ProductActions';
import {CartActions} from '@wix/wixstores-client-core/dist/es/src/cart-actions/cartActions';
import {PubSubManager} from '@wix/wixstores-client-core/dist/es/src/pub-sub-manager/pubSubManager';
import {ORIGIN, translationPath, PublicDataKeys, GalleryViewMode, FedopsEvent} from '../constants';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {getDefaultStyleParams} from '../commons/getDefaultStyleParams';
import * as _ from 'lodash';
import {productsPerPage} from './utils';
import {PaginationTypeName, PaginationType} from '@wix/wixstores-client-gallery/dist/es/src/types/galleryTypes';
import {ILink} from '@wix/wixstores-client-core/dist/es/src/types/site-map';

export class WishlistStore {
  private currentPage: number = 1;
  private multilingualService: MultilingualService;
  private products: IProduct[];
  private productsPerPage: number;
  private readonly cartActions: CartActions;
  private readonly fedopsLogger;
  private readonly productActions: ProductActions;
  private readonly pubSubManager: PubSubManager;
  private readonly wishlistService: WishlistService;
  private sectionUrl: string;
  private shouldReportFedops: boolean = true;
  private translations;
  private homepageLink: ILink;
  private addedToCartStatus: {[p: string]: ActionStatus} = {};

  constructor(
    private publicData: IControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly compId: string,
    private readonly type: string,
    private styleParams: IWishlistStyleParams,
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });
    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
    }

    this.pubSubManager = new PubSubManager(this.siteStore.pubSub);
    this.wishlistService = new WishlistService(this.siteStore, this.externalId);
    this.productActions = new ProductActions(this.siteStore);
    this.cartActions = new CartActions(this.siteStore, this.pubSubManager, ORIGIN, PageMap.CART);
    this.productsPerPage = productsPerPage(this.styleParams);
  }

  public async setInitialState(): Promise<void> {
    const [section, translations, appSettings, homepageLink] = await Promise.all([
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
      getTranslations(translationPath(this.siteStore.baseUrls.wishlistBaseUrl, this.siteStore.locale)),
      this.wishlistService.getAppSettings(),
      this.siteStore.getHomepageLink(),
    ]);

    this.sectionUrl = section.url;
    this.translations = translations;
    this.homepageLink = homepageLink;

    this.multilingualService = new MultilingualService(
      this.publicData.COMPONENT,
      appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    await this.updateComponent();

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  private async updateComponent() {
    const propsToInject = await this.getComputedProps();

    this.setProps(propsToInject);
  }

  private async getComputedProps(): Promise<IPropsInjectedByViewerScript> {
    const offset = (this.currentPage - 1) * this.productsPerPage;
    const limit = this.productsPerPage;
    const products = (this.products = await this.getProducts(limit, offset));
    const totalProducts = this.wishlistService.getTotalProducts();
    const hasMoreProducts = totalProducts - limit + offset > 0;

    return {
      ...this.getProductItemProps(),
      currentPage: this.currentPage,
      experiments: this.getExperiments(),
      handlePagination: this.handlePagination.bind(this),
      handleLoadMore: this.handleLoadMore.apply(this),
      handleClickOnEmptyState: this.handleClickOnEmptyState.bind(this),
      gridType: this.styleParams.numbers.gallery_gridType,
      hasMoreProducts,
      isAutoGrid: true,
      isLiveSiteMode: true,
      isLoaded: this.wishlistService.isLoaded(),
      isMobile: this.siteStore.isMobile(),
      loadMoreType: this.styleParams.numbers.gallery_loadMoreProductsType,
      onAppLoaded: this.onAppLoaded.bind(this),
      productSize: this.styleParams.numbers.gallery_productSize,
      products,
      removeProduct: this.removeProduct.bind(this),
      signature: this.wishlistService.signature(),
      styleParams: this.styleParams,
      textsMap: this.getTextsMap(),
      totalProducts,
      homePageUrl: this.homepageLink.url,
      isEmptyState: totalProducts > 0,
      paginationMode: this.getPaginationMode(),
      productsPerPage: this.productsPerPage,
    };
  }

  private async getProducts(limit: number, offset: number) {
    const viewMode =
      this.siteStore.isSiteMode() || this.siteStore.isPreviewMode()
        ? GalleryViewMode.LIVE_SITE
        : this.styleParams.numbers.gallery_editorViewMode;
    const products = await this.wishlistService.getProducts({
      limit,
      offset,
      viewMode,
      productsPerPage: this.productsPerPage,
    });
    if (viewMode === GalleryViewMode.EDITOR_DEMO_STATE) {
      products.forEach(p => (p.name = this.translations['wishlist.demoProduct.title']));
    }
    return products;
  }

  private async removeProduct(productId: string) {
    const index = this.products.findIndex(p => p.id === productId);
    const biEventData = this.createBiEventData(productId, index);
    this.fedopsLogger.interactionStarted(FedopsEvent.RemoveFromWishlist);
    this.reportToBI('clickRemoveFromWishlistSf', biEventData);

    this.products = this.products.filter(p => p.id !== productId);
    await this.updatePartialComponent({products: this.products});
    await this.wishlistService
      .removeProduct(productId)
      .then(() => {
        this.fedopsLogger.interactionEnded(FedopsEvent.RemoveFromWishlist);
        this.reportToBI('productRemovedFromWishlistSf', biEventData);
      })
      .catch(this.reportError);
    await this.updateComponent();
  }

  private reportToBI(eventName, eventData): void {
    (this.siteStore.biLogger as {})[eventName](eventData);
  }

  private createBiEventData(productId: string, index: number) {
    const {ribbon, options, id, productType} = this.pickProduct(productId);

    return {
      hasOptions: options.length > 0,
      hasRibbon: !!ribbon,
      index,
      productId: id,
      productType,
      origin: ORIGIN,
    };
  }

  private updatePartialComponent(props: Partial<IPropsInjectedByViewerScript>): Promise<void> {
    this.setProps(props);
    return Promise.resolve();
  }

  public async updateState(
    newStyleParams: IWishlistStyleParams,
    newPublicData: IControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    const nextStyleParams = getStyleParamsWithDefaults(newStyleParams, () => {
      return getDefaultStyleParams(newStyleParams);
    });
    this.updatePublicData(newPublicData);
    this.multilingualService.setPublicData(this.publicData.COMPONENT);
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);

    this.styleParams = {...nextStyleParams};
    this.productsPerPage = productsPerPage(this.styleParams);
    const propsToInject = await this.getComputedProps();
    this.setProps(propsToInject);
  }

  private updatePublicData(newPublicData: IControllerConfig['publicData']) {
    /* istanbul ignore next: hard to test it */
    this.publicData = _.merge(this.publicData, newPublicData);
  }

  private getProductItemProps() {
    return {
      addedToCartStatus: this.addedToCartStatus,
      handleAddToCart: this.handleAddToCart.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      openQuickView: this.handleOpenQuickView.bind(this),
      productIdToProductPageUrlMap: this.getProductUrls(),
      quantitySRKeys: {
        total: 'sr.totalQty',
        minimumAmount: 'wishlist.minimumQuantity.error',
        maximumAmount: 'wishlist.exceedsQuantity.error',
      },
      shouldShowAddToCartSuccessAnimation: true,
      updateAddToCartStatus: this.updateAddToCartStatus.bind(this),
    };
  }

  private readonly updateAddToCartStatus = (productId: string, status: ActionStatus) => {
    this.addedToCartStatus = {
      ...this.addedToCartStatus,
      [productId]: status,
    };

    return this.updatePartialComponent({addedToCartStatus: this.addedToCartStatus});
  };

  private getTextsMap(): IPropsInjectedByViewerScript['textsMap'] {
    return {
      addToCartContactSeller: this.translations['wishlist.contactSeller.button'],
      addToCartOutOfStock: this.translations['wishlist.outOfStock.button'],
      digitalProductBadgeAriaLabelText: this.translations['Digital Product'],
      emptyStateText:
        this.multilingualService.get(PublicDataKeys.NO_PRODUCTS_MESSAGE) || this.translations['wishlist.emptyState'],
      emptyStateLinkText:
        this.multilingualService.get(PublicDataKeys.EMPTY_STATE_LINK) || this.translations['wishlist.CTA'],
      galleryAddToCartButtonText:
        this.multilingualService.get(PublicDataKeys.ADD_TO_CART) || this.translations['wishlist.addToCart.button'],
      loadMoreButton:
        this.multilingualService.get(PublicDataKeys.LOAD_MORE_BUTTON) || this.translations['wishlist.loadMore.button'],
      productOutOfStockText: this.translations['wishlist.outOfStock.label'],
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quantityInputSR: this.translations['sr.quantity'],
      quantityAddSR: this.translations['sr.addQty'],
      quantityChooseAmountSR: this.translations['sr.chooseQty'],
      quantityRemoveSR: this.translations['sr.removeQty'],
      quickViewButtonText: this.translations['wishlist.quickView.button'],
      wishilistHeaderTitle:
        this.multilingualService.get(PublicDataKeys.TITLE_TEXT) || this.translations['wishlist.title'],
      wishlistHeaderSubtitle:
        this.multilingualService.get(PublicDataKeys.SUBTITLE_TEXT) || this.translations['wishlist.description'],
    };
  }

  private getExperiments(): IPropsInjectedByViewerScript['experiments'] {
    return {
      isShowQuantityExperimentEnabled: false,
      isAddToCartButtonEnabled: true,
    };
  }

  private getProductUrls() {
    return this.products.reduce(
      (acc, product) => ({...acc, [product.id]: `${this.sectionUrl}/${product.urlPart}`}),
      {}
    );
  }

  private async handleProductItemClick({biData: {index, productId}}) {
    const eventData = this.createBiEventData(productId, index);
    this.reportToBI('clickOnProductBoxSf', eventData);
    await this.siteStore.navigate({
      sectionId: PageMap.PRODUCT,
      state: this.pickProduct(productId).urlPart,
      queryParams: undefined,
    });
  }

  private async handlePagination(page: number) {
    this.currentPage = page;
    await this.updateComponent();
  }

  private handleLoadMore() {
    const batchSize = this.productsPerPage;

    return async () => {
      this.productsPerPage += batchSize;
      await this.updateComponent();
    };
  }

  private async handleAddToCart({productId, quantity}: {productId: string; quantity: number}) {
    await this.cartActions.addToCart(productId, [], quantity, [], this.styleParams.numbers.gallery_addToCartAction);
    return this.updateAddToCartStatus(productId, ActionStatus.SUCCESSFUL);
  }

  private handleOpenQuickView({productId}) {
    const product = this.pickProduct(productId);
    return this.productActions.quickViewProduct(product.id, ORIGIN, product.urlPart, this.compId, this.externalId);
  }

  private pickProduct(productId: string) {
    return this.products.find(p => p.id === productId);
  }

  private async handleClickOnEmptyState(): Promise<void> {
    await this.siteStore.biLogger.clickLinkInMembersWishlistSf({});
    this.siteStore.navigateToLink(this.homepageLink);
  }

  private getPaginationMode(): PaginationTypeName {
    return this.siteStore.isMobile() || this.styleParams.numbers.gallery_paginationFormat === PaginationType.COMPACT
      ? 'compact'
      : 'pages';
  }

  /* istanbul ignore next: hard to test it */
  public onAppLoaded(): void {
    if (this.shouldReportFedops) {
      this.fedopsLogger.appLoaded();
      this.shouldReportFedops = false;
    }
  }
}
